<template>
  <div class="entrance">
    <h1 class="h_none">蓝灯鱼AI开放平台</h1>
    <h2 class="h_none">蓝灯鱼,lanternfish,蓝灯鱼商标,蓝灯鱼翻译,蓝灯鱼专利,AI,人工智能</h2>
    <img src="/images/result_show_logo.png" class="h_none">
    <div v-if="showOverlay" class="overlay" @click="hideOverlay"></div>
    <div class="entrance_content">
      <img
        style="width: 100%; height: auto; vertical-align: middle"
        src="../../assets/images/entrance/entrance1.png"
      />

      <div style="display: flex;position: absolute;height: 100%;width: 100%;top: 0">

        <div :class="['entrance_item',{'active': !patentSearch}]">
          <el-carousel ref="carousel" @change="carouselChange" arrow="never" indicator-position="none" v-if="patentSearch" class="entrance_carousel" style="width: 100%;height: 100%">
            <el-carousel-item style="width: 100%;height: 100%">
              <div class="shell" @mouseenter="mouseenter(3)" style="height: 100%;width: 100%">
                <div :class="['entrance_content_item',{animated: patentSearch},{fadeInDown: patentSearch}]">
                  <p>专利翻译</p>
                  <p style="font-size: 20px">Patent translation</p>
                  <p style="font-size: 16px">拥有1.2亿全球专利数据库，基于专利代理行业20年专利翻译数据库，提取5000万+语料数据，支持中英、英中、中日、日中四个语言方向。</p>
                </div>
              </div>
            </el-carousel-item>

            <el-carousel-item style="width: 100%;height: 100%">
              <img @mouseenter="mouseenter(4)" style="width: 100%;height: 100%" @click="microBlog" src="../../assets/images/entrance/xingzhe.png" alt="">
            </el-carousel-item>

            <el-carousel-item style="width: 100%;height: 100%">
              <div class="shell" @mouseenter="mouseenter(3)" style="height: 100%;width: 100%">
                <div :class="['entrance_content_item',{animated: patentSearch},{fadeInDown: patentSearch}]">
                  <p>专利翻译</p>
                  <p style="font-size: 20px">Patent translation</p>
                  <p style="font-size: 16px">拥有1.2亿全球专利数据库，基于专利代理行业20年专利翻译数据库，提取5000万+语料数据，支持中英、英中、中日、日中四个语言方向。</p>
                </div>
              </div>
            </el-carousel-item>

            <el-carousel-item style="width: 100%;height: 100%">
              <img @mouseenter="mouseenter(4)" style="width: 100%;height: 100%" @click="microBlog" src="../../assets/images/entrance/xingzhe.png" alt="">
            </el-carousel-item>

            <ul class="carousel_btn">
              <li @mouseover="carouselhover(0)" :class="[{'active': activeFlage}]"></li>
              <li @mouseover="carouselhover(1)" :class="[{'active': !activeFlage}]"></li>
            </ul>
          </el-carousel>

          <div v-show="!patentSearch" :class="['entrance_content_item_function',{animated: !patentSearch},{fadeInUp: !patentSearch}]">
            <p>专利翻译</p>
            <p>Patent translation</p>
            <div style="height: 180px">
              <ul>
                <li :class="[{'active': item.show }]" v-for="(item,index) in languageType" :key="index"
                    @click="changeLangueType(item.id)">{{item.text}}
                </li>
              </ul>
              <div>
                <el-input
                  class="translation_textarea"
                  :rows="4"
                  style="cursor: none"
                  resize='none'
                  placeholder="每日免费翻译3000字，输入..."
                  type="textarea"
                  v-model="transValue"
                />
              </div>
              <div @click="translate" class="translate">译</div>
            </div>
          </div>
        </div>

        <div :class="['entrance_item',{'active': !trademark}]" @mouseenter="mouseenter(2)">
          <div v-show="trademark" :class="['entrance_content_item',{animated: trademark},{fadeInDown: trademark}]">
            <p>商标查询</p>
            <p>Trademark search</p>
            <p>覆盖全球43个国家/地区商标数据，AI技术同时支持图片查询和文字查询。</p>
          </div>
          <div v-show="!trademark" :class="['entrance_content_item_function',{animated: !trademark},{fadeInUp: !trademark}]">
            <p>商标查询</p>
            <p>Trademark search</p>
            <div style="margin-top: 70px; height: 200px">
              <el-radio-group class="radio_group" v-model="radio">
                <el-radio :label="2">名称近似</el-radio>
                <el-radio :label="3">申请人</el-radio>
                <el-radio :label="5">申请号</el-radio>
                <el-radio :label="6">代理机构</el-radio>
              </el-radio-group>
              <div class="input_search" style="display: flex">
                <div :style="{width: '100%'}" :class="['input-single']">
                  <div class="input_box">
                    <el-input
                      style="height: 100%; border: none"
                      class="input-con"
                      ref="searchInput"
                      placeholder="请输入商品名称/申请号/申请人"
                      @keyup.enter.native="searchTrademark(1)"
                      v-model="searchText"
                    >
                      <template class="prepend_input" slot="prepend">
                        <div>
                          <img style="vertical-align: middle;width: 32px;" src='/images/national/zgdl.jpg'/>
                          <i @click="searchTrademark(0)" style="color: #2E54D8;margin-left: 5px" class="el-icon-caret-bottom rotate"/>
                        </div>
                      </template>
                    </el-input>
                  </div>
                  <div class="camera-box">
                    <img @click="searchTrademark(0)" src="@/assets/images/query/camera1_2.png" style="margin-top: 5px;margin-right: 17px;" class="camera-btn camera-btn1"/>
                  </div>
                  <p @click="searchTrademark(1)" class="query-btn"></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div :class="['entrance_item',{'active': !patentTranslation}]">
          <div @mouseover.prevent="mouseenter(1)" v-show="patentTranslation" :class="['entrance_content_item',{animated: patentTranslation},{fadeInDown: patentTranslation}]">
            <p>中小微企业智产托管平台</p>
            <p>IP&CQ Trusteeship Platform for SMEs</p>
            <p>针对中小微企业智产的一键代管平台，可实现实时监控提醒，商标续展、专利年费免费代缴；<br/>智享创新、智化管理、尽在“掌”握。</p>
          </div>
          <div v-show="!patentTranslation" :class="['entrance_content_item_function',{animated: !patentTranslation},{fadeInUp: !patentTranslation}]">
            <p>中小微企业智产托管平台</p>
            <p style="margin-bottom: 20px">IP&CQ Trusteeship Platform for SMEs</p>
            <div style="height: 180px">
              <div>
                <el-input
                  class="translation_textarea1"
                  style="cursor: none"
                  resize='none'
                  placeholder="请输入要托管的申请人名称"
                  v-model="transValue"
                />
              </div>
              <div @click="toHostPlatform(transValue)" class="translate">立即试用</div>
            </div>
          </div>
        </div>
      </div>

      <ul class="entry">
        <li>
          <el-popover
            placement="left"
            width="150"
            trigger="hover"
          >
            <img style="width: 100%;height: auto" src="../../assets/images/about/kf.png" alt="">
            <div slot="reference" class="listy">
              <img src="../../assets/images/entrance/kefu.png" alt/>
              <span>添加客服微信</span>
            </div>
          </el-popover>
        </li>
<!--        <li @click="jump()">-->
<!--          <img src="../../assets/images/entrance/jiaoc.png" alt="">-->
<!--          <span>视频教程</span>-->
<!--        </li>-->
        <li  @click="jump()">
          <el-tooltip       v-if="isReady" ref="tooltip"  :manual="true"
                       v-model:visible="visible" effect="light" placement="left">
            <!-- 自定义内容通过 slot#content -->
            <template #content>
              <div>
                <h3 style="font-size: 14px;margin-bottom: 5px">视频教程上新啦</h3>
                <p style="font-size: 14px">新增驳通智写、OA 精灵、芯智写的操作演示</p>
              </div>
            </template>

            <!-- 触发元素 -->
            <div style="display: flex;flex-direction: column;justify-content: center;align-items: center">
              <div class="step">下一步</div>
              <img src="../../assets/images/entrance/jiaoc.png" alt="">
              <span>视频教程</span>
            </div>
          </el-tooltip>
        </li>
      </ul>
    </div>
    <footers></footers>
  </div>
</template>

<script>
  import footers from '@/components/common/footers'
  import {mapState,mapMutations} from 'vuex'

  export default {
    name: "index",
    metaInfo: {
      title: '商标查询|专利翻译|知识产权管理系统-蓝灯鱼智能检索平台', // set a title
      meta: [{                 // set meta
        name: 'keyWords',
        content: '蓝灯鱼,商标查询，商标近似查询，商标图形查询，专利翻译，专利查询，专利检索'
      }, {                 // set meta
        name: 'description',
        content: '蓝灯鱼是将人工智能技术运用到知识产权行业的平台。通过AI技术智能化商标查询,专利翻译,专利检索；提供在线使用和API接入等服务'
      }],
    },
    data() {
      return {
        showOverlay: true, // 控制遮罩层的显示与隐藏
        isReady: false,
        visible: true,
        activeFlage: true,
        //专利检索
        patentValue: '',
        //翻译内容
        transValue: '',
        radio: 2,
        searchText: '',
        //商标查询
        input3: '',
        select: '',
        languageType: [
          {
            text: '中文',
            id: 1,
            show: true,
            "languageDirection": "zh-cn",
            "isActive": true
          },
          {
            text: '英文',
            id: 2,
            show: false,
            "languageDirection": "en-us",
            "isActive": false
          },
          {
            text: '日文',
            id: 3,
            show: false,
            "languageDirection": "ja-jp",
            "isActive": false
          }
        ],
        patentType: [
          {
            text: '语义检索',
            id: 1,
            show: true
          },
          {
            text: '简单检索',
            id: 2,
            show: false
          },
          {
            text: '高级检索',
            id: 3,
            show: false
          },
          {
            text: '批量检索',
            id: 4,
            show: false
          }
        ],
        languageValue: '',
      }
    },
    components: {
      footers
    },
    computed: {
      ...mapState({
        // 专利翻译
        patentTranslation: state => state.patentTranslation,
        // 商标
        trademark: state => state.trademark,
        // 专利检索
        patentSearch: state => state.patentSearch,
        // 专利检索
        userSource: state => state.userSource
      })
    },
    mounted(){
      console.log(document.referrer,"1234")
      if(this.$route.query.user_source){
        switch (this.$route.query.user_source) {
          case 'baidu': this.$store.commit('setUserSource',{
            name: 'baidu',
            token: 'YmFpZHU6YmFpZHU=',
            path: 'pages/auths/main'
          });break;
          case 'zhihu': this.$store.commit('setUserSource',{
            name: 'zhihu',
            token: 'emhpaHU6emhpaHU=',
            path: 'pages/auths/main'
          });break;
          case 'app': this.$store.commit('setUserSource',{
            name: 'app',
            token: 'YXBwOmFwcA==',
            path: 'pages/auths/main'
          });break;
        }
      }
      window.scrollTo(0, 10); // 第一个参数是x坐标，第二个参数是y坐标
      this.isReady = true;
      // 在渲染完成后强制刷新位置
      this.$nextTick(() => {
        window.scrollTo(0, 10); // 第一个参数是x坐标，第二个参数是y坐标
        this.$refs.tooltip.updatePopper();
      });
      // 延迟显示 Tooltip 并更新位置
      setTimeout(() => {
        window.scrollTo(0, 10); // 第一个参数是x坐标，第二个参数是y坐标
        this.isReady = true;
        // 在渲染完成后强制刷新位置
        this.$refs.tooltip.updatePopper();
      }, 1000); // 延迟 200ms
    },
    methods: {
      ...mapMutations("query", [
        "setClassifyShowFn",
        "queryItemsShowFn",
        "queryDataFn",
        "ajaxResponseFn",
        "othersItemFn",
        "pageNum_Fn",
        "pageSize_Fn",
        "noResetFn",
        "queryBtnClickNumFn",
        "responseDataFn",
        "sendQueryDataFn",
        "isFirstQueryFn",
        "asideFormFn",
        'setMe',
        'setNoticeList',
        'setUserSource'
      ]),
      hideOverlay() {
        // this.showOverlay = false; // 点击遮罩层隐藏
      },
      toHostPlatform(keyword){
        this.$router.push({
          path: '/hostingPlatform',
          query: {
            keyword: keyword
          }
        });
      },
      carouselhover(item) {
        this.$refs.carousel.setActiveItem(item)
      },
      //幻灯片切换
      carouselChange(value) {
        if (value == 0 || value == 2) this.activeFlage = true
        else this.activeFlage = false
      },
      // 视频连接
      jump() {
        this.$router.push({
          path: '/tutorial'
        });
      },
      //跳到微博
      microBlog() {
        window.open("https://forum.lanternfish.cn");
      },
      //商标查询
      searchTrademark(item) {
        if (item == 0) {
          //带参数跳转
          this.$router.push({
            path: '/PQ'
          });
        } else {
          this.$router.push({
            path: '/PQ',
            query: {
              value: this.radio,
              searchText: this.searchText,
              auto: 1
            }
          });
        }
      },
      trans() {
        this.patentTranslation = false
      },
      translate() {
        let obj = this.languageType.filter(item => item.show)[0]
        localStorage.setItem('transValue', this.transValue)
        //带参数跳转
        this.$router.push({
          path: '/PT',
          query: {
            languageDirection: encodeURIComponent(JSON.stringify(obj))
          }
        });
      },
      //patentSearch
      patentS() {
        localStorage.setItem('patentValue', this.patentValue)
        //带参数跳转
        this.$router.push({
          path: '/PS',
          query: {
            patentValue: 1
          }
        });
      },
      changePatentType(id) {
        // this.patentType.map(item => {
        //   if (item.id == id) return item.show = true
        //   else return item.show = false
        // })
        if (id != 1) {
          //带参数跳转
          this.$router.push({
            path: '/PS',
            query: {
              id: id - 1
            }
          });
        }
      },
      changeLangueType(id) {
        this.languageType.map(item => {
          if (item.id == id) return item.show = true
          else return item.show = false
        })
      },
      mouseenter(item) {
        this.$store.commit('setPatentTranslation', true)
        this.$store.commit('setTrademark', true)
        this.$store.commit('setPatentSearch', true)
        if (item == 4) return;
        switch (item) {
          case 1:
            this.$store.commit('setPatentTranslation', false)
            break;
          case 2:
            this.$store.commit('setTrademark', false)
            break;
          case 3:
            this.$store.commit('setPatentSearch', false)
            break;
          default:
            break;
        }
      },
      // mouseleave(item) {
      //   if(this.patentTranslation && this.trademark && this.patentSearch)  return;
      //   switch (item) {
      //     case 1:
      //       this.$store.commit('setPatentTranslation',true)
      //       break;
      //     case 2:
      //       this.$store.commit('setTrademark',true)
      //       break;
      //     case 3:
      //       this.$store.commit('setPatentSearch',true)
      //       break;
      //     default:
      //       break;
      //   }
      // }
    }
  }
</script>

<style lang="less">
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色 */
    z-index: 100;
  }
  .translation_textarea {
    .el-textarea__inner {
      border-radius: 0 12px 12px 12px;
    }
  }
  .translation_textarea1 {
    margin-top: 70px;
    .el-textarea__inner {
      border-radius:12px;
    }
  }
</style>

<style lang="less" scoped>
  .entrance {
    .entrance_content {
      position: relative;
      min-height: 500px;
      background: #1c3790;
      /*background: url("/images/entrance.png") no-repeat;*/
      /*background-size: 100%, 100%;*/

      .entrance_item {
        width: 34%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:nth-of-type(1) {
          /*background: linear-gradient(0deg, rgba(7, 146, 195, 0.48) 0%, rgba(26, 61, 191, 0.48) 100%);*/
          &.active {
            background: linear-gradient(115deg, #0792C3 0%, #1A3DBF 94%);
          }
        }

        &:nth-of-type(2) {
          /*background: linear-gradient(0deg, rgba(7, 146, 195, 0.60) 0%, rgba(26, 61, 191, 0.60) 100%);*/

          &.active {
            background: linear-gradient(115deg, #3B40FD 6%, #151879 91%);
          }
        }

        &:nth-of-type(3) {
          &.active {
            background: linear-gradient(115deg, #2D73F6 0%, #1D245F 95%);
          }
        }

        .entrance_content_item {
          width: 359px;
          height: 400px;
          p:nth-of-type(1) {
            font-size: 30px;
            font-weight: 500;
            color: #FFFFFF;
          }

          p:nth-of-type(2) {
            margin-top: 20px;
            font-size: 20px;
            font-weight: 400;
            color: #DAD7D7;
          }

          p:nth-of-type(3) {
            margin-top: 44px;
            font-size: 16px;
            font-weight: 400;
            color: #F4F3F3;
            line-height: 36px;
          }

          &.animated {
            animation-duration: 2s;
          }
        }

        .entrance_content_item_function {
          width: 83%;
          height: 400px;

          &.animated {
            animation-duration: 0.5s;
          }

          p:nth-of-type(1) {
            font-size: 28px;
            font-weight: 500;
            color: #FFFFFF;
          }

          p:nth-of-type(2) {
            font-size: 20px;
            font-weight: 400;
            color: #DAD7D7;
            margin-top: 13px;
          }

          ul {
            margin-top: 70px;
            display: flex;

            li {
              color: #EEF0F4;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 90px;
              height: 40px;
              border-radius: 8px 8px 0px 0px;

              &.active {
                color: #2F56DE;
                background: #FFFFFF;
                border: 1px solid #C2C6D0;
              }

              &:hover {
                color: #2F56DE;
                background: #FFFFFF;
                border: 1px solid #C2C6D0;
              }
            }
          }

          .translate {
            width: 100px;
            height: 38px;
            font-size: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 400;
            margin-top: 20px;
            color: #FFFFFF;
            background: #2F56DE;
            box-shadow: 0px 0px 12px 0px rgba(30, 30, 30, 0.15);
            border-radius: 8px;
            float: right;
          }
          .radio_group {
            @media screen and (max-width: 1500px) {
              .el-radio {
                margin-right: 15px !important;
              }
            }

            @media screen and (max-width: 1400px) {
              .el-radio {
                margin-right: 10px !important;
              }
            }
            /deep/ .el-radio__label {
              color: white;
            }
          }
        }

        .entrance_carousel {
          /deep/ .el-carousel__container {
            height: 100%;
          }

          /deep/ .entrance_content_item {
            width: 359px;
            p:nth-of-type(1) {
              font-size: 30px;
              font-weight: 500;
              color: #FFFFFF;
            }
            p:nth-of-type(2) {
              margin-top: 20px;
              font-size: 28px;
              font-weight: 400;
              color: #DAD7D7;
            }
            p:nth-of-type(3) {
              margin-top: 44px;
              font-size: 18px;
              font-weight: 400;
              color: #F4F3F3;
              line-height: 36px;
            }
            &.animated {
              animation-duration: 2s;
            }
          }

          /deep/ .shell {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .carousel_btn {
            position: absolute;
            bottom: 45px;
            right: 39px;
            z-index: 2;
            display: flex;

            li {
              width: 14px;
              height: 14px;
              background: #08061E;
              border-radius: 50%;

              &:nth-of-type(1) {
                margin-right: 15px;
              }

              &.active {
                background: #456EFE !important;
              }
            }
          }
          /deep/ .el-carousel__indicators {
            display: none;
            left: 60px;
            bottom: 28px;
            li {
              .el-carousel__button {
                width: 14px;
                height: 14px;
                background: #08061E;
                border-radius: 50%;
              }
              &.is-active {
                .el-carousel__button {
                  background: #456EFE;
                }
              }
              &:nth-of-type(1) {
                margin-right: 5px;
              }
              &:nth-of-type(2) {
                margin-right: 5px;
              }
              &:nth-of-type(3) {
                display: none;
              }
              &:nth-of-type(4) {
                display: none;
              }
            }
          }
        }
      }
      .entry {
        z-index: 50;
        li {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;

          &:nth-of-type(1) {
            bottom: 120px;
            right: 49px;
            z-index: 50;
          }
          &:nth-of-type(2) {
            position: absolute;
            bottom: 50px;
            right: 58px;
            z-index: 999;
            .step {
              color: white;
              font-size: 16px;
              font-weight: 600;
              position: relative;
              top: -35px;
              left: -178px;
              &:before {
                position: absolute;
                content: '';
                left: 23px;
                top: 18px;
                background: #7C85A7;
                width: 2px;
                height: 25px;
              }
              &:after {
                position: absolute;
                content: '';
                left: 19px;
                top: 42px;
                width: 10px;
                height: 10px;
                background: #0636C6;
                border-radius: 50%;
              }
            }
          }
          img {
            width: 38px;
            height: 38px;
          }

          span {
            margin-top: 8px;
            font-size: 12px;
            font-weight: 300;
            color: #FFFFFF;
            line-height: 17px;
          }

          &:nth-of-type(1) {
            margin-bottom: 22px;
          }
        }

        .listy {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 38px;
            height: 38px;
          }

          span {
            margin-top: 8px;
            font-size: 12px;
            font-weight: 300;
            color: #FFFFFF;
            line-height: 17px;
          }
        }
      }

      @media screen and (max-width: 1880px) {
        .entry {
          bottom: 78px;
        }
      }

      @media screen and (max-width: 1700px) {
        .entry {
          right: 40px;
          bottom: 10px;
        }
      }

      @media screen and (max-width: 1600px) {
        .entry {
          right: 35px;
          bottom: -20px;
        }
      }

      @media screen and (max-width: 1200px) {
        min-height: 500px;
      }

    }
  }
</style>
<style lang='less'>
  .entrance {
    /* 确保 Tooltip 的父级和 body 没有限制 */
    .el-tooltip__popper {
      z-index: 2000; /* 确保 Tooltip 在页面最上层 */
      position: fixed !important; /* 避免受父级影响 */
    }
    .entrance_content {
      .input_search {
        height: 36px;
        margin: 23px auto 0;
        /*width: 808px;*/

        .input-single {
          height: 36px;
          background: #FFFFFF;
          border: 2px solid #2E54D9;
          border-radius: 12px;
          display: flex;
          position: relative;

          .category {
            width: 101px;
            text-align: right;
            font-size: 14px;
            letter-spacing: 1px;
            color: #333333;
            cursor: pointer;
          }

          .input_box {
            flex: 1;
            position: relative;
            /*border-left: 1px solid #c2c6d0;*/

            .input-con {
              width: 100%;
              height: 100%;
              border: 0; // 去除未选中状态边框
              outline: none; // 去除选中状态边框
              background-color: rgba(0, 0, 0, 0); // 透明背景
              input {
                height: 100%;
                border: 0; // 去除未选中状态边框
              }

              /deep/ .el-input__inner {
                line-height: 36px !important;
              }

              /deep/ .el-input__inner {
                line-height: 36px !important;
              }

              /deep/ .el-input-group__prepend {
                /*display: flex;*/
                /*align-items: center;*/
                width: 100px;
                position: relative;
                background: #193dbe;
                box-sizing: border-box;
                padding: 0 14px;
                border-radius: 12px 0px 0px 12px;
              }

              .el-input-group__prepend {
                padding: 0 14px;
                border-radius: 12px 0px 0px 12px;
                border-right: 2px solid #2E54D9;
              }

              .el-icon-caret-bottom {
                -webkit-transition: -webkit-transform 0.3s ease;
                -moz-transition: -moz-transform 0.3s ease;
                transition: transform 0.3s ease;
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                transform: rotate(180deg);

                &.rotate {
                  -webkit-transform: rotate(0deg);
                  -moz-transform: rotate(0deg);
                  transform: rotate(0deg);
                }
              }
            }
          }

          .camera2 {
            padding: 0 26px 0 11px;
            box-sizing: border-box;
            font-size: 0;
            background: #fff;

            .el-upload {
              width: 38px;

              .camera-box {
                width: 38px;
                line-height: 36px;
                overflow: hidden;
                background: white;

                &:hover {
                  .camera-btn1 {
                    display: none;
                  }

                  .camera-btn2 {
                    display: inline;
                  }
                }

                .camera-btn {
                  width: 26px;
                  vertical-align: middle;
                }

                .camera-btn1 {
                  display: inline;
                }

                .camera-btn2 {
                  display: none;
                }

              }
            }
          }

          .query-btn {
            cursor: pointer;
            width: 68px;
            height: 37px;
            border-radius: 0 12px 12px 0;
            background: url("@{imgUrl}entrance/search.png") no-repeat center;
            margin-top: -1px;
            margin-right: -1px;
            background-size: 32%;
            border-left: 2px solid #2E54D9;
          }


          &.borderBlue {
            border-color: @blue;

            .input_box {
              border-color: @blue;
            }
          }
        }

        .search_btn {
          background: #1A3DBF;
          border-radius: 12px;
          font-size: 14px;
          font-weight: 600;
          color: #FEFEFE;

          &:hover {
            background: #1A3DBF;
            color: #FFFFFF;
            box-shadow: 0px 0px 7px 1px rgba(14, 36, 64, 0.12);
          }
        }

        .el-upload-list {
          position: absolute;
          left: 110px;
          top: 0;
          background: #fff;

          &:focus {
            outline: none;
            border: none;
          }
        }

        .el-upload-list--picture .el-upload-list__item {
          border: none;
          border-radius: 0;
          padding: 0 0 0 90px;
          box-sizing: border-box;
          height: 26px;
          line-height: 26px;
          margin-top: 7px;
          transition: none !important;

          &:focus {
            outline: none;
            border: none;
          }
        }

        .el-upload-list--picture .el-upload-list__item-thumbnail {
          width: auto;
          height: 26px;
        }

        .el-upload-list--picture .el-upload-list__item-name {
          margin-top: 0;
          margin-left: -15px;
        }

        .el-upload-list--picture .el-upload-list__item-name i {
          font-size: 0;
        }

        .el-upload-list__item .el-icon-close {
          top: 10px;
        }
      }
    }
  }
</style>

<style lang="less" scoped>
  .el-row {
    height: auto;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    border-radius: 4px;
  }

  .bg-purple-dark {
    background: #99a9bf;
  }

  .bg-purple {
    background: #d3dce6;
  }

  .bg-purple-light {
    background: #e5e9f2;
  }

  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }

  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
</style>
